@import "src/App/variables";
@import "src/App/mixins";

.header-tab {
  line-height: $header-height;
  height: $header-height;

  &.active {
    border-bottom: 2px solid;
    border-bottom-color: var(--tab-active-border-color);
    color: var(--tab-active-text-color);
  }
}
