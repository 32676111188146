@import "src/App/variables";

.scroll-indicator {
  box-shadow: 0 -15px 50px 0px #fff;
  i {
    animation: bounce 2s infinite;
    @keyframes bounce {
      0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-14px);
      }
      60% {
        transform: translateY(-7px);
      }
    }
  }
}
