@import 'App/variables';

$color_1: #666666;
$color_2: #3CB8F0;
$color_3: #CCCCCC;

.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
}

@media screen and (max-width: 959px) {
  .rc-dropdown {
    left: 0 !important;
    width: 100vw !important;
  }
}

.rc-dropdown-hidden {
  display: none;
}

.rc-dropdown-menu {
  outline: none;
  position: relative;
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: left;
  background-color: white;
  box-shadow: 0 1px 5px $keet-border-grey;
  background-clip: padding-box;
  border: 1px solid $keet-border-grey;

  > li {
    margin: 0;
    padding: 0;
  }

  >.rc-dropdown-menu-item {
    cursor: pointer;
    position: relative;
    display: block;
    padding: 12px;
    clear: both;
    font-size: 12px;
    color: $color_1;
    white-space: nowrap;
    &:hover {
      background-color: $keet-background-grey;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  }

  >.rc-dropdown-menu-item-active {
    background-color: $keet-background-grey;
  }

  >.rc-dropdown-menu-item-disabled {
    color: $color_3;
    cursor: not-allowed;
    pointer-events: none;
    &:hover {
      color: $color_3;
      background-color: white;
      cursor: not-allowed;
    }
  }

  >.rc-dropdown-menu-item-divider {
    height: 1px;
    overflow: hidden;
    background-color: $keet-border-grey;
    line-height: 0;
  }
}

.rc-dropdown-slide-up-enter {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-dropdown-slide-up-appear {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-dropdown-slide-up-leave {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpIn;
  animation-name: rcDropdownSlideUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownIn;
  animation-name: rcDropdownSlideDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft {
  -webkit-animation-name: rcDropdownSlideUpOut;
  animation-name: rcDropdownSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter {
  -webkit-animation-name: rcDropdownSlideUpOut;
  animation-name: rcDropdownSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
  -webkit-animation-name: rcDropdownSlideUpOut;
  animation-name: rcDropdownSlideUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft {
  -webkit-animation-name: rcDropdownSlideDownOut;
  animation-name: rcDropdownSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter {
  -webkit-animation-name: rcDropdownSlideDownOut;
  animation-name: rcDropdownSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
  -webkit-animation-name: rcDropdownSlideDownOut;
  animation-name: rcDropdownSlideDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


@-webkit-keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}


@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}


@-webkit-keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}


@-webkit-keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}

@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  100% {
    opacity: 0;
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
  }
}
