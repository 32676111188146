@import "src/App/variables";
@import "src/App/keet_icons";
@import '~react-md/src/scss/react-md';

$md-light-theme: true;
$md-btn-include-icon: false;

body[data-theme='keet'] {
  $md-primary-color: $keet-navy;
  $md-secondary-color: $keet-magenta;
  --header-logo-height: 35px;
  --tab-text-color: #263849;
  --tab-active-border-color: #D81F7B;
  --tab-active-text-color: #D81F7B;
  --message-color: rgb(216, 31, 123);
  --tab-color: rgb(218, 0, 123);
  --tab-color-new: rgb(241, 161, 204);
  --theme-primary-color: #375784;
  --theme-secondary-color: #D81F7B;
  --theme-tertiary-color: #00BABC;
  --theme-loader-color: #aac2e3;
}

body[data-theme='ptnow'] {
  $md-primary-color: $ptnow-purple;
  $md-secondary-color: $ptnow-lavender;
  --header-logo-height: 55px;
  --tab-text-color: rgb(38, 56, 73);
  --tab-active-border-color: rgb(5, 195, 222);
  --tab-active-text-color: rgb(5, 195, 222);
  --message-color: rgb(149, 120, 211);
  --tab-color: rgb(0, 0, 0);
  --tab-color-new: rgb(0, 0, 0);
  --theme-primary-color: #485cc7;
  --theme-secondary-color: #485cc7;
  --theme-tertiary-color: #485cc7;
  --theme-loader-color: #B3BDF5;
}

@include react-md-accessibility;
@include react-md-buttons;
@include react-md-colors;
@include react-md-dialogs;
@include react-md-grid;
@include react-md-overlays;
@include react-md-papers;
@include react-md-pickers;
@include react-md-typography;

html, body, #root {
  margin: 0;
  height: auto;
  min-height: 100vh;
  width: 100%;
}

html {
  body {
    background: $keet-background-grey;
  }

  h1, h2, h3, h4, h5, h6, button, input, textarea, html, p, span {
    color: $text-color-general;
  }

  button, input, textarea {
    font-size: 14px;
    outline: none;
  }

  * {
    font-family: "Helvetica Neue", 'Ubuntu', sans-serif;
    box-sizing: border-box;
    border-color: $keet-border-grey;
  }
}

.keet-navy {
  color: $keet-navy;
}

.bg-keet-navy {
  background-color: $keet-navy;
}

.keet-teal {
  color: $keet-teal;
}

.bg-keet-teal {
  background-color: $keet-teal;
}

.keet-magenta {
  color: $keet-magenta;
}

.bg-keet-magenta {
  background-color: $keet-magenta;
}

.ptnow-purple {
  color: $ptnow-purple;
}

.bg-ptnow-purple {
  background-color: $ptnow-purple;
}

.ptnow-blue {
  color: $ptnow-blue;
}

.bg-ptnow-blue {
  background-color: $ptnow-blue;
}

.ptnow-lavender {
  color: $ptnow-lavender;
}

.bg-ptnow-lavender {
  background-color: $ptnow-lavender;
}

// Tachyons Extension
.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}

.of-cover {
  object-fit: cover;
}

.bg-primary {
  background-color: var(--theme-primary-color);
}

.bg-secondary {
  background-color: var(--theme-secondary-color);
}

.bg-tertiary {
  background-color: var(--theme-tertiary-color);
}

.primary {
  color: var(--theme-primary-color);
}

.secondary {
  color: var(--theme-secondary-color);
}

.tertiary {
  color: var(--theme-tertiary-color);
}
