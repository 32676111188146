@import "src/App/variables";

.login-screen {
  label {
    background-color: #fff;
    padding-bottom: 2px;
    width: 100%;
  }
}

.registration-form {
  width: 100%;
}

.panel {
  flex: inherit;

  .index_title {
    color: var(--theme-primary-color);
    font-size: 2em;
  }

  .registration-link {
    color: var(--theme-primary-color);
  }

  .alert {
    margin-bottom: 0.8em;
  }

  .flash {
    max-width: 16rem;
    text-align: center;
    color: red;
  }

  .center {
    text-align: center;
  }
}

.md-text-field-divider-container {
  margin-left: 0 !important;
}
