.ReactModal__Overlay {
  z-index: 2;
  overflow-y: scroll;
  top: 0px;
  left: inherit !important;
  right: inherit !important;
  bottom: inherit !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
}
