@import "src/App/variables";

#completed-modal {
  background-color: $success-background;

  h1, h3, p {
    color: #fff
  }

  button {
    color: $keet-light-grey;
    background-color: #fff;
    border: 0;
  }

  .hooray-circle {
    width: 200px;
    height: 200px;
    border-color: #95FCFC;
    border-width: 1.4rem;
  }
}
