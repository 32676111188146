.ReactPlayer {
  width: 100% !important;
  max-width: 425px !important;
  max-height: 250px !important;
  iframe {
    max-width: 100%;
  }
}

.taskDetails {
  max-width: 482px;
}
