.checkboxStyle {
  display: block;
  min-height: 30px;
  line-height: 30px;
  margin-left: 8px;
  white-space: normal;
}

@media screen and (max-width: 479px) {
  .checkboxStyle {
    padding-bottom: 1.5rem;

    .ant-checkbox {
      .ant-checkbox-inner {
        width: 31px !important;
        height: 31px !important;

        &:after {
          left: 9px;
          top: 2px;
          height: 18px;
          width: 11px;
          border: 4px solid #fff;
          border-top: 0;
          border-left: 0;
        }
      }
    }
    .ant-checkbox + span {
      padding-right: 0;
      position: relative;
    }
  }
}
