.radioStyle {
  display: block;
  min-height: 30px;
  line-height: 30px;
  white-space: normal;
  margin-left: .5rem;
};

@media screen and (max-width: 479px) {
  .radioStyle {
    padding-bottom: .75rem;
    padding-left: .75rem;
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;

    .ant-radio {
      .ant-radio-inner {
        width: 31px !important;
        height: 31px !important;

        &:after {
          left: 7px;
          top: 7px;
          height: 15px;
          width: 15px;
        }
      }
    }
    .ant-radio + span {
      padding-right: 0;
      position: relative;
      top: -7px;
    }
  }
}
