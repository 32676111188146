.base-content {
  display: flex;
  align-items: center;
  padding-bottom: .5em;
}
.content {
  margin-left: 1em;
}
.content-title {
  margin-bottom: 0;
  font-size: 1.25em;
}
