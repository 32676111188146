@import "src/App/variables";

#keet-header {
  height: $header-height;

  #header-logo {
    height: var(--header-logo-height);
  }

  #header-profile {
    height: $header-height;

    img {
      height: 44px;
      width: 44px;
      object-fit: cover;
    }

    #header-name {
      color: $keet-navy;
    }
  }
}

.header-login-text {
  color: #263849;
}

.inactiveMenuItem {
  cursor: default !important;
  pointer-events: none;
  color: $keet-light-grey !important;
}

.header-tab {
  line-height: $header-height;
  height: $header-height;
  color: var(--tab-text-color);
}
