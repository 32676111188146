$keet-grey: #898989;
$keet-magenta: #D81F7B;
$keet-navy: #375784;
$keet-teal: #00BABC;
$keet-light-grey: #AAAAAA;
$keet-background-grey: #F5F8FA;
$keet-border-grey: rgba(0, 0, 0, 0.12);
$ptnow-lavender: #9578d3;
$ptnow-purple: #485cc7;
$ptnow-blue: #05c3de;
$header-height: 64px;
$nps-red: #DB3236;
$nps-yellow: #F3C20D;
$nps-green: #3DBA53;
$text-color-general: #3E505A;
$success-background: #2C6CA7;
$contact-bubble: #ec5444;
