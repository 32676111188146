.tabs {
  display: flex;
  flex-grow: 1;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  list-style-type: none;
  cursor: pointer;
  font-size: 20px;
}
.selected {
  border-bottom: 3px solid var(--tab-color);
}
.contain-new {
  border-bottom: 3px solid var(--tab-color);
}
