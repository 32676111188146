@charset "UTF-8";

@font-face {
  font-family: "keet_icons";
  src: url("../myfontastic/myfontastic.eot");
  src: url("../myfontastic/myfontastic.svg#1481189361") format("svg"),
    url("../myfontastic/myfontastic.eot?#iefix") format("embedded-opentype"),
    url("../myfontastic/myfontastic.woff") format("woff"),
    url("../myfontastic/myfontastic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

// end font awesome

[data-icon]:before {
  font-family: "keet_icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="cb-icon-"]:before,
[class*=" cb-icon-"]:before {
  font-family: "keet_icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// IMPORTANT
.cb-icon-move:before{
  content: "\e00c";
}

.cb-icon-pencil:before{
  content: "\e053";
}

.cb-icon-plus:before{
  content: "\e056";
}

.cb-icon-cancel:before{
  content: "\e005";
}

.cb-icon-right-open-big:before{
  content: "\3c";
}

.cb-icon-left-open-big:before{
  content: "\3d";
}

.cb-icon-code:before{
  content: "\e02b";
}

.cb-icon-color:before{
  content: "\e01f";
}

.cb-icon-bold:before{
  content: "\e089";
}

.cb-icon-italic:before{
  content: "\e08a";
}

.cb-icon-header:before{
  content: "\e08c";
}

.cb-icon-header:before{
  content: "\e08c";
}

.cb-icon-list-bullet:before{
  content: "\e091";
}

.cb-icon-link:before{
  content: "\e08b";
}

.cb-icon-underline:before{
  content: "\e09f";
}

.cb-icon-strike:before{
  content: "\e0a0";
}

.cb-icon-fontsize:before{
  content: "\e0a3";
}

.cb-icon-eraser:before{
  content: "\e0a2";
}

.cb-icon-font:before{
  content: "\e0a1";
}

.cb-icon-align-justify:before{
  content: "\e08e";
}

.cb-icon-unlink:before{
  content: "\e0a5";
}

// IMPORTANT

.icon-clock:before {
  content: "\61";
}
.icon-android-bicycle:before {
  content: "\62";
}
.icon-ios-checkmark-outline:before {
  content: "\63";
}
.icon-ios-checkmark:before {
  content: "\64";
}
.icon-ios-bookmarks-outline:before {
  content: "\66";
}
.icon-ios-body-outline:before {
  content: "\67";
}
.icon-happy-smiley-streamline:before {
  content: "\68";
}
.icon-edit-modify-streamline:before {
  content: "\69";
}
.icon-shoe:before {
  content: "\6a";
}
.icon-bubble-ask-1:before {
  content: "\6b";
}
.icon-file-list:before {
  content: "\6c";
}
.icon-clock-1:before {
  content: "\6e";
}
.icon-mountain-bike:before {
  content: "\6d";
}
.icon-dumbbells:before {
  content: "\6f";
}
.icon-alarm-clock:before {
  content: "\70";
}
.icon-clipboard-edit:before {
  content: "\71";
}
.icon-arrow-circle-30:before {
  content: "\72";
}
.icon-clipboard-1:before {
  content: "\73";
}
.icon-bubble-attention-5:before {
  content: "\74";
}
.icon-pin-1:before {
  content: "\75";
}
.icon-camera-2:before {
  content: "\76";
}
.icon-notes-2:before {
  content: "\77";
}
.icon-headphone-2:before {
  content: "\78";
}
.icon-gramophone:before {
  content: "\79";
}
.icon-drug:before {
  content: "\7a";
}
.icon-play-2:before {
  content: "\41";
}
.icon-pause-2:before {
  content: "\42";
}
.icon-equalizer-3:before {
  content: "\43";
}
.icon-edit-1:before {
  content: "\44";
}
.icon-clip-1:before {
  content: "\45";
}
.icon-edit-2:before {
  content: "\46";
}
.icon-smiley-happy-4:before {
  content: "\48";
}
.icon-pencil-2:before {
  content: "\49";
}
.icon-check-circle-2:before {
  content: "\4a";
}
.icon-calendar-1:before {
  content: "\4b";
}
.icon-alert-1:before {
  content: "\4c";
}
.icon-information:before {
  content: "\4d";
}
.icon-question-mark:before {
  content: "\4e";
}
.icon-pulse-graph-1:before {
  content: "\4f";
}
.icon-pulse-graph-3:before {
  content: "\50";
}
.icon-bin-2:before {
  content: "\51";
}
.icon-user-add-2:before {
  content: "\52";
}
.icon-user-edit-2:before {
  content: "\53";
}
.icon-user-delete-2:before {
  content: "\54";
}
.icon-profile-doctor-2:before {
  content: "\55";
}
.icon-profile-lady-1:before {
  content: "\56";
}
.icon-profile-athlete:before {
  content: "\57";
}
.icon-profile-nurse1:before {
  content: "\58";
}
.icon-profile-nurse-2:before {
  content: "\59";
}
.icon-hourglass-1:before {
  content: "\5a";
}
.icon-alarm-snooze:before {
  content: "\30";
}
.icon-bell:before {
  content: "\31";
}
.icon-pie-chart-2:before {
  content: "\32";
}
.icon-pie-chart-1:before {
  content: "\33";
}
.icon-business-chart-2:before {
  content: "\34";
}
.icon-treasure-map:before {
  content: "\35";
}
.icon-bubble-conversation-2:before {
  content: "\36";
}
.icon-bubble-comment-1:before {
  content: "\37";
}
.icon-window-graph-1:before {
  content: "\38";
}
.icon-pulse:before {
  content: "\21";
}
.icon-contacts-2:before {
  content: "\22";
}
.icon-mail-2:before {
  content: "\23";
}
.icon-bubble-ask-2:before {
  content: "\24";
}
.icon-bookmark-4:before {
  content: "\25";
}
.icon-book-7:before {
  content: "\26";
}
.icon-book-4:before {
  content: "\27";
}
.icon-preview-1:before {
  content: "\28";
}
.icon-magnifier:before {
  content: "\2a";
}
.icon-smartphone:before {
  content: "\2b";
}
.icon-calendar-2:before {
  content: "\2c";
}
.icon-delete-3:before {
  content: "\2d";
}
.icon-add-3:before {
  content: "\2e";
}
.icon-next-2:before {
  content: "\2f";
}
.icon-delete-1:before {
  content: "\3a";
}
.icon-arrow-65:before {
  content: "\3b";
}
.icon-arrow-68:before {
  content: "\3c";
}
.icon-arrow-67:before {
  content: "\3d";
}
.icon-arrow-66:before {
  content: "\3e";
}
.icon-ios-circle-outline:before {
  content: "\3f";
}
.icon-ios-circle-filled:before {
  content: "\40";
}
.icon-ios-pulse:before {
  content: "\5b";
}
.icon-clipboard:before {
  content: "\5f";
}
.icon-arrow-32:before {
  content: "\60";
}
.icon-arrow-circle-13:before {
  content: "\7b";
}
.icon-arrow-circle-14:before {
  content: "\7c";
}
.icon-arrow-31:before {
  content: "\7d";
}
.icon-arrow-8:before {
  content: "\7e";
}
.icon-arrow-7:before {
  content: "\5c";
}
.icon-profile-1:before {
  content: "\e000";
}
.icon-user-2:before {
  content: "\e001";
}
.icon-sign-new-2:before {
  content: "\e002";
}
.icon-alert-2:before {
  content: "\e003";
}
.icon-delete-2:before {
  content: "\e005";
}
.icon-log-out-1:before {
  content: "\e007";
}
.icon-android-menu:before {
  content: "\5e";
}
.icon-ios-drag:before {
  content: "\e004";
}
.icon-navicon:before {
  content: "\5d";
}
.icon-trophy:before {
  content: "\e006";
}
.icon-binocular:before {
  content: "\e008";
}
.icon-ios-eye-outline:before {
  content: "\e009";
}
.icon-glasses-2:before {
  content: "\e00a";
}
.icon-connection-2:before {
  content: "\e00b";
}
.icon-power-2:before {
  content: "\29";
}
.icon-arrow-move:before {
  content: "\e00c";
}
.icon-play-1:before {
  content: "\e00d";
}
.icon-sad:before {
  content: "\e00e";
}
.icon-printer:before {
  content: "\e00f";
}
.icon-ipad:before {
  content: "\e010";
}
.icon-phone:before {
  content: "\e011";
}
.icon-laptop:before {
  content: "\e012";
}
.icon-ico-education:before {
  content: "\e013";
}
.icon-combined-shape:before {
  content: "\e014";
}
.icon-ico-search:before {
  content: "\e016";
}
.icon-fa-arrow-circle-o-up:before {
  content: "\e015";
}
.icon-fa-arrow-left:before {
  content: "\e017";
}
.icon-fa-arrow-right:before {
  content: "\e018";
}
.icon-fa-arrows:before {
  content: "\e019";
}
.icon-fa-bar-chart:before {
  content: "\e01a";
}
.icon-fa-flash:before {
  content: "\e01b";
}
.icon-fa-book:before {
  content: "\e01c";
}
.icon-fa-calendar:before {
  content: "\e01d";
}
.icon-fa-calendar-o:before {
  content: "\e01e";
}
.icon-fa-certificate:before {
  content: "\e01f";
}
.icon-fa-check:before {
  content: "\e020";
}
.icon-fa-check-circle-o:before {
  content: "\e021";
}
.icon-fa-chevron-circle-left:before {
  content: "\e022";
}
.icon-fa-chevron-down:before {
  content: "\e023";
}
.icon-fa-chevron-left:before {
  content: "\e024";
}
.icon-fa-chevron-right:before {
  content: "\e025";
}
.icon-fa-chevron-up:before {
  content: "\e026";
}
.icon-fa-circle:before {
  content: "\e027";
}
.icon-fa-clipboard:before {
  content: "\e028";
}
.icon-fa-clock-o:before {
  content: "\e029";
}
.icon-fa-cloud-download:before {
  content: "\e02a";
}
.icon-fa-gears:before {
  content: "\e02b";
}
.icon-fa-comment-o:before {
  content: "\e02c";
}
.icon-fa-comments-o:before {
  content: "\e02d";
}
.icon-fa-crosshairs:before {
  content: "\e02e";
}
.icon-fa-cube:before {
  content: "\e02f";
}
.icon-fa-download:before {
  content: "\e031";
}
.icon-fa-envelope:before {
  content: "\e033";
}
.icon-fa-envelope-o:before {
  content: "\e034";
}
.icon-fa-envelope-square:before {
  content: "\e035";
}
.icon-fa-exclamation:before {
  content: "\e036";
}
.icon-fa-fax:before {
  content: "\e038";
}
.icon-fa-file:before {
  content: "\e039";
}
.icon-fa-file-o:before {
  content: "\e03a";
}
.icon-fa-file-text-o:before {
  content: "\e03b";
}
.icon-fa-file-video-o:before {
  content: "\e03c";
}
.icon-fa-files-o:before {
  content: "\e03d";
}
.icon-fa-filter:before {
  content: "\e03e";
}
.icon-fa-flag-checkered:before {
  content: "\e03f";
}
.icon-fa-folder-open-o:before {
  content: "\e040";
}
.icon-fa-frown-o:before {
  content: "\e041";
}
.icon-fa-gear:before {
  content: "\e042";
}
.icon-fa-users:before {
  content: "\e044";
}
.icon-fa-heart:before {
  content: "\e045";
}
.icon-fa-heart-o:before {
  content: "\e046";
}
.icon-fa-inbox:before {
  content: "\e047";
}
.icon-fa-line-chart:before {
  content: "\e048";
}
.icon-fa-magic:before {
  content: "\e04a";
}
.icon-fa-map-marker:before {
  content: "\e04b";
}
.icon-fa-map-o:before {
  content: "\e04c";
}
.icon-fa-medkit:before {
  content: "\e04d";
}
.icon-fa-meh-o:before {
  content: "\e04e";
}
.icon-fa-minus:before {
  content: "\e04f";
}
.icon-fa-newspaper-o:before {
  content: "\e050";
}
.icon-fa-paper-plane-o:before {
  content: "\e051";
}
.icon-fa-paperclip:before {
  content: "\e052";
}
.icon-fa-pencil:before {
  content: "\e053";
}
.icon-fa-pencil-square-o:before {
  content: "\e054";
}
.icon-fa-phone:before {
  content: "\e055";
}
.icon-fa-plus:before {
  content: "\e056";
}
.icon-fa-plus-circle:before {
  content: "\e057";
}
.icon-fa-plus-square:before {
  content: "\e058";
}
.icon-fa-question-circle:before {
  content: "\e05a";
}
.icon-fa-quote-left:before {
  content: "\e05b";
}
.icon-fa-quote-right:before {
  content: "\e05c";
}
.icon-fa-refresh:before {
  content: "\e05d";
}
.icon-fa-remove:before {
  content: "\e05e";
}
.icon-fa-repeat:before {
  content: "\e05f";
}
.icon-fa-reply:before {
  content: "\e060";
}
.icon-fa-road:before {
  content: "\e061";
}
.icon-fa-rocket:before {
  content: "\e062";
}
.icon-fa-sitemap:before {
  content: "\e064";
}
.icon-fa-smile-o:before {
  content: "\e065";
}
.icon-fa-sort:before {
  content: "\e066";
}
.icon-fa-sort-alpha-asc:before {
  content: "\e067";
}
.icon-fa-sort-alpha-desc:before {
  content: "\e068";
}
.icon-fa-sort-amount-asc:before {
  content: "\e069";
}
.icon-fa-sort-amount-desc:before {
  content: "\e06a";
}
.icon-fa-tablet:before {
  content: "\e06c";
}
.icon-fa-tags:before {
  content: "\e06d";
}
.icon-fa-thumb-tack:before {
  content: "\e06e";
}
.icon-fa-thumbs-o-up:before {
  content: "\e06f";
}
.icon-fa-thumbs-up:before {
  content: "\e070";
}
.icon-fa-times:before {
  content: "\e071";
}
.icon-fa-times-circle:before {
  content: "\e072";
}
.icon-fa-trophy:before {
  content: "\e073";
}
.icon-fa-unlock:before {
  content: "\e074";
}
.icon-fa-user:before {
  content: "\e075";
}
.icon-fa-user-md:before {
  content: "\e076";
}
.icon-fa-user-times:before {
  content: "\e077";
}
.icon-fa-white:before {
  content: "\e078";
}
.icon-fa-wrench:before {
  content: "\e079";
}
.icon-fa-star:before {
  content: "\e030";
}
.icon-fa-star-o:before {
  content: "\e032";
}
.icon-ic-back:before {
  content: "\e037";
}
.icon-calculator:before {
  content: "\e043";
}
.icon-exclamation-circle:before {
  content: "\e049";
}
.icon-fa-trash:before {
  content: "\e059";
}
.icon-fa-minus-circle:before {
  content: "\e063";
}
.icon-m-custom-tag:before {
  content: "\e06b";
}
.icon-m-satisfaction:before {
  content: "\e07a";
}
.icon-m-trigger:before {
  content: "\e07b";
}
.icon-m-users:before {
  content: "\e07c";
}
.icon-m-diagnosis:before {
  content: "\e07d";
}
.icon-m-form-question:before {
  content: "\e07f";
}
.icon-m-form-score:before {
  content: "\e080";
}
.icon-m-gender:before {
  content: "\e081";
}
.icon-m-home-program:before {
  content: "\e082";
}
.icon-m-last-login:before {
  content: "\e083";
}
.icon-m-no-reviews:before {
  content: "\e084";
}
.icon-m-nps:before {
  content: "\e085";
}
.icon-m-nps-trend:before {
  content: "\e086";
}
.icon-m-physician:before {
  content: "\e087";
}
.icon-m-region:before {
  content: "\e088";
}
.icon-fa-bold:before {
  content: "\e089";
}
.icon-fa-italic:before {
  content: "\e08a";
}
.icon-fa-link:before {
  content: "\e08b";
}
.icon-fa-header:before {
  content: "\e08c";
}
.icon-fa-camera:before {
  content: "\e08d";
}
.icon-fa-align-justify:before {
  content: "\e08e";
}
.icon-fa-align-left:before {
  content: "\e08f";
}
.icon-fa-align-right:before {
  content: "\e090";
}
.icon-fa-youtube-play:before {
  content: "\e092";
}
.icon-fa-th:before {
  content: "\e091";
}
.icon-m-bar:before {
  content: "\e093";
}
.icon-m-activity:before {
  content: "\e094";
}
.icon-fa-angle-up:before {
  content: "\e096";
}
.icon-fa-angle-down:before {
  content: "\e097";
}
.icon-fa-exclamation-triangle:before {
  content: "\e098";
}
.icon-question:before {
  content: "\e095";
}
.icon-check-mark:before {
  content: "\e09a";
}
.icon-ico-play:before {
  content: "\e09b";
}
.icon-m-chat:before {
  content: "\e099";
}
.icon-bubble-add-o-2:before {
  content: "\e09d";
}
.icon-bubble-add-o-circle:before {
  content: "\e09e";
}
.icon-bubble-add-2:before {
  content: "\47";
}
.icon-hexagon-o:before {
  content: "\e07e";
}
.icon-hexagon:before {
  content: "\e09c";
}
.icon-ico-forms:before {
  content: "\65";
}
.icon-fa-underline:before {
  content: "\e09f";
}
.icon-fa-strikethrough:before {
  content: "\e0a0";
}
.icon-fa-font:before {
  content: "\e0a1";
}
.icon-fa-eraser:before {
  content: "\e0a2";
}
.icon-fa-fonticons:before {
  content: "\e0a3";
}
.icon-fa-link-broken:before {
  content: "\e0a5";
}
.icon-m-notication:before {
  content: "\e0a4";
}
.icon-fa-list:before {
  content: "\39";
}
.icon-insert-button:before {
  content: "\e0a6";
}
.icon-break:before {
  content: "\e0a7";
}
.icon-ico-other-list:before {
  content: "\e0a8";
}
.icon-education-bold:before {
  content: "\e0a9";
}
.icon-education-italic:before {
  content: "\e0aa";
}
.icon-education-link:before {
  content: "\e0ab";
}
.icon-education-header:before {
  content: "\e0ac";
}
.icon-education-quote:before {
  content: "\e0ad";
}
.icon-minus-3:before {
  content: "\e0ae";
}
