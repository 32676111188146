@import 'src/App/variables';

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: $keet-border-grey;
}

.circle-progress {
  stroke: var(--theme-tertiary-color);
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: var(--theme-tertiary-color);
}
