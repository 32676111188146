@import "src/App/variables";

$title-height: 48px;

.message-container {
  display: flex;
  align-items: flex-end;
}

.message-container-right {
  justify-content: flex-end;
}

.circle {
  border-radius: 50%;
}
.chat-group-header {
  font-size: .8em;
  color: gray;
  margin-bottom: 1rem;
}

#scene-messages {
  .message-height {
    height: calc(100vh - 120px);
  }

  .message-search {
    height: $title-height;
  }

  .message-selector-desktop {
    max-width: 420px;
    flex-basis: 25%;
  }

  #messages-selector {
    min-width: 240px;

    .selector-item {
      &:hover, &.active {
        background: $keet-background-grey;
      }

      img {
        height: 50px;
        width: 50px;
        object-fit: cover;
      }
    }
  }

  .messages-window {
    .message-scroll {
      overflow-y: scroll;
      height: calc(100vh - 120px);
    }

    #window-title {
      height: $title-height;

      img {
        object-fit: cover;
      }
    }

    .mobile-window-chat {
      height: calc(100vh - 155px);
    }

    .desktop-window-chat {
      height: 466px;
    }

    #window-chat {
      .chat-item {
        max-width: 70%;
        margin-bottom: 4px;

        &.right {
          margin-left: auto;

          .item-text {
            background-color: var(--message-color);
            color: white;
          }
        }

        &.left {
          margin-right: auto;

          .item-text {
            background-color: #F1F0F0;
          }
        }
      }
    }

    #window-input {
      textarea {
        resize: none;
      }

      button {
        transition: all 0.5s ease;

        &:disabled {
          color: $keet-light-grey;
          cursor: default;
        }
      }
    }
  }
}
